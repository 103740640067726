import {
  Avatar,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import InputMask from "react-input-mask";
import CloseCircle from "../../assets/images/close-circle.png";
import EmployeesPayAdjustment from "../employees/EmployeesPayAdjustment";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import axios from "../../axios";
import Loading from "../common/Loading";
import { getCompanies, getPositions, getStations } from "../../store/data/data.selectors";
import EditBig from "../../assets/images/settings/edit-big.png";
import EmployeesModalPayHistory from "./EmployeesModalPayHistory";
import { ReactComponent as GustoIcon } from "../../assets/images/employees/is_gusto.svg";
import { getIsDemoMode } from "../../store/auth/auth.selectors";
import { dateToHumanDiff } from "../../helpers/helpers"
import PaychexLogo from "../../assets/images/home/paychex-logo.png";

const schema = yup.object().shape({
  first_name: yup.string().required("First Name is required!"),
  last_name: yup.string().required("Last Name is required!"),
  fedex_id: yup.string().required("Fedex Id is required!"),
  // employee_id: yup.string().required("Employee Id is required!"),
  contract_service_area: yup.string().required("Contract Service Area is required!"),
  primary_company: yup.string().required("Primary Company is required!"),
  primary_station: yup.string().required("Primary Station is required!"),
  email: yup.string().email("Invalid format")
});

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  },
  errorSelect: {
    borderColor: "red"
  },
  selectErrorMessage: {
    color: "red !important",
    margin: "-8px 14px 0"
  },
  switch: {
    width: "130px",
    "& .MuiTypography-root": {
      fontFamily: "Gilroy",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#717E94"
    },
    "& .MuiSwitch-root .MuiSwitch-track": {
      backgroundColor: "#7B87F5 !important"
    },
    "& .MuiSwitch-root .MuiButtonBase-root .MuiSwitch-thumb": {
      color: "#aab4be !important"
    },
    "& .MuiSwitch-root .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb": {
      color: "#7B87F5 !important"
    }
  }
};

const EmployeesModal = ({ open, handleClose, editedUserId, setIsEditComplete }) => {
  const dispatch = useDispatch();

  const companies = useSelector(getCompanies);
  const stations = useSelector(getStations);
  const positions = useSelector(getPositions);
  const isDemoMode = useSelector(getIsDemoMode);

  const [editedUser, setEditedUser] = useState();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isPayHistory, setIsPayHistory] = useState(false);
  const [avatar, setAvatar] = useState();
  const [isActive, setIsActive] = useState(editedUserId ? false : true);
  const [activeLoading, setActiveLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [isGusto, setIsGusto] = useState(false);
  const [isGustoOnboarded, setIsGustoOnboarded] = useState(false);
  const [isPaychex, setIsPaychex] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [latesIntegration, setLatesIntegration] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      first_name: "",
      last_name: "",
      company_position_id: "",
      notes: "",
      fedex_id: null,
      stop_pickup_rate: null,
      stop_delivery_rate: null,
      stop_ecommerce_rate: null,
      package_pickup_rate: null,
      package_delivery_rate: null,
      package_ecommerce_rate: null,
      adding_daily_pay: 1,
      subtracting_daily_pay: 0,
      deleted_stop_bonuses: [],
      deleted_package_bonuses: [],
      deleted_code_85_deduction_bonus: [],
      add_stop_bonus: 0,
      add_package_bonus: 0,
      add_per_package: 0,
      add_ils_addition_bonus: 0,
      add_ils_deduction_bonus: 0,
      add_all_status_code_package_deduction_bonus: 0,
      add_dna_deduction_bonus: 0,
      add_miss_pickup_window_deduction_bonus: 0,
      add_early_late_pickup_deduction_bonus: 0,
      add_code_85_deduction_bonus: 0,
      add_per_stop: 0,
      is_overtime_enabled: 0,
      is_work_area_enabled: 0
    },
    resolver: yupResolver(schema)
  });

  const {
    primary_station,
    primary_company,
    company_position_id,
    adding_daily_pay,
    stop_bonuses,
    code_85_deduction_bonus,
    package_bonuses,
    deleted_package_bonuses,
    deleted_code_85_deduction_bonus,
    deleted_stop_bonuses,
    add_stop_bonus,
    add_package_bonus,
    add_per_package,
    add_ils_addition_bonus,
    add_ils_deduction_bonus,
    add_all_status_code_package_deduction_bonus,
    add_dna_deduction_bonus,
    all_status_code_package_deduction_bonus,
    add_miss_pickup_window_deduction_bonus,
    add_early_late_pickup_deduction_bonus,
    add_code_85_deduction_bonus,
    add_per_stop,
    is_overtime_enabled,
    weekly_rate_w2,
    is_work_area_enabled,
    ils_addition_bonus,
    ils_deduction_bonus,
    dna_deduction_bonus,
    miss_pickup_window_deduction_bonus,
    early_late_pickup_deduction_bonus
  } = watch();

  useEffect(() => {
    if (editedUserId) {
      setLoading(true);
      axios
        .get(`/employee/${editedUserId}`)
        .then((res) => {
          const employeeData = res.data.employee;
          const employeeDataRates = employeeData.rates;

          const isConnectGusto = employeeData.gusto_company_integrations?.length > 0;
          // Gust
          setIsGusto(isConnectGusto);

          if (isConnectGusto) {
              setLatesIntegration(employeeData.gusto_company_integrations.reduce((latest, current) => {
                  return new Date(current.updated_at) > new Date(latest.updated_at) ? current : latest;
              }));
          }

          setIsGustoOnboarded(
              employeeData.gusto_company_integrations?.length > 0 &&
              employeeData.gusto_company_integrations.every(integration => integration.onboarded === 1)
          );

            // Paychex
            setIsPaychex(employeeData.paychex_company_integrations?.length > 0);

          setEditedUser(employeeData);
          setIsActive(employeeData.active);

          setIsPaychex(employeeData.paychex_company_integrations?.length > 0);

          employeeData.first_name &&
            setValue("first_name", employeeData.first_name, {
              shouldValidate: true,
              shouldDirty: true
            });
          employeeData.last_name &&
            setValue("last_name", employeeData.last_name, {
              shouldValidate: true,
              shouldDirty: true
            });
          employeeData.email &&
            setValue("email", employeeData.email, { shouldValidate: true, shouldDirty: true });
          setPhone(employeeData.phone_no);
          setValue("fedex_id", employeeData.fedex_id, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("employee_id", employeeData.employee_id, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("contract_service_area", employeeData.station?.csa, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("station_id", employeeData.station?.id, {
            shouldValidate: true,
            shouldDirty: true
          });
          employeeData.company_position_id &&
            setValue("company_position_id", employeeData.company_position_id, {
              shouldValidate: true,
              shouldDirty: true
            });
          employeeData.notes &&
            setValue("notes", employeeData.notes, {
              shouldValidate: true,
              shouldDirty: true
            });
          employeeData.primary_company &&
            setValue("primary_company", employeeData.primary_company, {
              shouldValidate: true,
              shouldDirty: true
            });
          employeeData.primary_station &&
            setValue("primary_station", employeeData.station?.csa, {
              shouldValidate: true,
              shouldDirty: true
            });
          if (employeeData.bonuses) {
            const stop_bonuses = employeeData.bonuses.filter((el) => el.per_item === "stop");
            const package_bonuses = employeeData.bonuses.filter((el) => el.per_item === "package");
            setValue("stop_bonuses", stop_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            });
            setValue("package_bonuses", package_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            });
          }
          if (employeeData.bonus_adjustments) {
            const ils_addition_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "addition" && el.field === "ils"
            );
            const ils_deduction_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "deduction" && el.field === "ils"
            );
            const code_85_deduction_bonuses = employeeData.bonus_adjustments.filter(
              (el) => el.action_type === "deduction" && el.field === "code_85"
            );
            const all_status_code_package_deduction_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "deduction" && el.field === "all_status_code_package"
            );
            const dna_deduction_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "deduction" && el.field === "dna"
            );
            const miss_pickup_window_deduction_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "deduction" && el.field === "miss_pickup_window"
            );
            const early_late_pickup_deduction_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "deduction" && el.field === "early_late_pickup"
            );

            setValue("ils_addition_bonus", ils_addition_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            });
            setValue("ils_deduction_bonus", ils_deduction_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            });
            setValue(
              "all_status_code_package_deduction_bonus",
              all_status_code_package_deduction_bonuses,
              {
                shouldValidate: true,
                shouldDirty: true
              }
            );
            setValue("dna_deduction_bonus", dna_deduction_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            });
            setValue("miss_pickup_window_deduction_bonus", miss_pickup_window_deduction_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            });
            setValue("early_late_pickup_deduction_bonus", early_late_pickup_deduction_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            });
            setValue("code_85_deduction_bonus", code_85_deduction_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            });
          }
          setValue("is_overtime_enabled", employeeData.rates.is_overtime_enabled, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("is_work_area_enabled", employeeData.rates.is_work_area_enabled || 0, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("training_rate", employeeDataRates.training_rate, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("adding_daily_pay", employeeDataRates.adding_daily_pay, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("subtracting_daily_pay", employeeDataRates.subtracting_daily_pay, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("weekly_rate_w2", employeeDataRates.weekly_rate_w2, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("daily_pay", employeeDataRates.daily_pay, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("hourly_rate", employeeDataRates.hourly_rate, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("six_day_bonus", employeeDataRates.six_day_bonus, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("sunday_bonus", employeeDataRates.sunday_bonus, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("paid_time_off", employeeDataRates.paid_time_off, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("stop_pickup_rate", employeeDataRates.stop_pickup_rate, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("stop_delivery_rate", employeeDataRates.stop_delivery_rate, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("stop_ecommerce_rate", employeeDataRates.stop_ecommerce_rate, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("package_pickup_rate", employeeDataRates.package_pickup_rate, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("package_delivery_rate", employeeDataRates.package_delivery_rate, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("package_ecommerce_rate", employeeDataRates.package_ecommerce_rate, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("add_per_stop", employeeDataRates.add_per_stop, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("add_per_package", employeeDataRates.add_per_package, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("add_ils_addition_bonus", employeeDataRates.add_ils_addition_bonus, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("add_ils_deduction_bonus", employeeDataRates.add_ils_deduction_bonus, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue(
            "add_all_status_code_package_deduction_bonus",
            employeeDataRates.add_all_status_code_package_deduction_bonus,
            {
              shouldValidate: true,
              shouldDirty: true
            }
          );
          setValue("add_dna_deduction_bonus", employeeDataRates.add_dna_deduction_bonus, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue(
            "add_miss_pickup_window_deduction_bonus",
            employeeDataRates.add_miss_pickup_window_deduction_bonus,
            {
              shouldValidate: true,
              shouldDirty: true
            }
          );
          setValue(
            "add_early_late_pickup_deduction_bonus",
            employeeDataRates.add_early_late_pickup_deduction_bonus,
            {
              shouldValidate: true,
              shouldDirty: true
            }
          );
          setValue("add_code_85_deduction_bonus", employeeDataRates.add_code_85_deduction_bonus, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("add_stop_bonus", employeeDataRates.add_stop_bonus, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue("add_package_bonus", employeeDataRates.add_package_bonus, {
            shouldValidate: true,
            shouldDirty: true
          });
        })
        .catch(() => {
          handleClose();
          dispatch(
            toastActions.toast({ code: "error", message: "This Employee cannot be edited" })
          );
        })
        .finally(() => setLoading(false));
    }
  }, [editedUserId]);

  const handleCurrentClose = () => {
    handleClose();
    reset();
    setValue("primary_company", null, { shouldDirty: true });
    setValue("primary_station", null, { shouldDirty: true });
    setValue("station_id", null, { shouldDirty: true });
    setAvatar();
    setIsPayHistory(false);
  };

  const onSubmit = (values) => {
    const curStopBonuses =
      values.stop_bonuses
        ?.filter((el) => el.per_stop !== "" || el.daily_threshold !== "")
        .map((el) => (el.id ? el : { ...el, id: 0, per_item: "stop" })) || [];
    const curPackageBonuses =
      values.package_bonuses
        ?.filter((el) => el.per_stop !== "" || el.daily_threshold !== "")
        .map((el) => (el.id ? el : { ...el, id: 0, per_item: "package" })) || [];
    const body = {
      ...values,
      ...(editedUserId && { id: editedUserId }),
      ...((curStopBonuses || curPackageBonuses) && {
        bonuses: [...curStopBonuses, ...curPackageBonuses]
      }),
      ...(phone && { phone_no: phone }),
      deleted_bonus_adjustments: deleted_code_85_deduction_bonus,
      deleted_bonuses: [...deleted_stop_bonuses, ...deleted_package_bonuses],
      bonus_adjustments: [
        ...(code_85_deduction_bonus || []),
        ...(!ils_addition_bonus?.id &&
        (ils_addition_bonus?.field_value || ils_addition_bonus?.price_value) &&
        !!add_ils_addition_bonus
          ? [
              {
                id: 0,
                action_type: "addition",
                bonus_type: "default",
                field: "ils",
                field_value: ils_addition_bonus?.field_value || 0,
                price_type: ils_addition_bonus?.price_type || "amount",
                price_value: ils_addition_bonus?.price_value || 0
              }
            ]
          : ils_addition_bonus?.id
            ? [ils_addition_bonus]
            : []),
        ...(!ils_deduction_bonus?.id &&
        (ils_deduction_bonus?.field_value || ils_deduction_bonus?.price_value) &&
        !!add_ils_deduction_bonus
          ? [
              {
                id: 0,
                action_type: "deduction",
                bonus_type: "default",
                field: "ils",
                field_value: ils_deduction_bonus?.field_value || 0,
                price_type: ils_deduction_bonus?.price_type || "amount",
                price_value: ils_deduction_bonus?.price_value || 0
              }
            ]
          : ils_deduction_bonus?.id
            ? [ils_deduction_bonus]
            : []),
        ...(!all_status_code_package_deduction_bonus?.id &&
        (all_status_code_package_deduction_bonus?.field_value ||
          all_status_code_package_deduction_bonus?.price_value) &&
        !!add_all_status_code_package_deduction_bonus
          ? [
              {
                id: 0,
                action_type: "deduction",
                bonus_type: "default",
                field: "all_status_code_package",
                field_value: all_status_code_package_deduction_bonus?.field_value || 0,
                price_type: all_status_code_package_deduction_bonus?.price_type || "amount",
                price_value: all_status_code_package_deduction_bonus?.price_value || 0
              }
            ]
          : all_status_code_package_deduction_bonus?.id
            ? [all_status_code_package_deduction_bonus]
            : []),
        ...(!dna_deduction_bonus?.id &&
        (dna_deduction_bonus?.field_value || dna_deduction_bonus?.price_value) &&
        !!add_dna_deduction_bonus
          ? [
              {
                id: 0,
                action_type: "deduction",
                bonus_type: "default",
                field: "dna",
                field_value: dna_deduction_bonus?.field_value || 0,
                price_type: dna_deduction_bonus?.price_type || "amount",
                price_value: dna_deduction_bonus?.price_value || 0
              }
            ]
          : dna_deduction_bonus?.id
            ? [dna_deduction_bonus]
            : []),
        ...(!miss_pickup_window_deduction_bonus?.id &&
        (miss_pickup_window_deduction_bonus?.field_value ||
          miss_pickup_window_deduction_bonus?.price_value) &&
        !!add_miss_pickup_window_deduction_bonus
          ? [
              {
                id: 0,
                action_type: "deduction",
                bonus_type: "default",
                field: "miss_pickup_window",
                field_value: miss_pickup_window_deduction_bonus?.field_value || 0,
                price_type: miss_pickup_window_deduction_bonus?.price_type || "amount",
                price_value: miss_pickup_window_deduction_bonus?.price_value || 0
              }
            ]
          : miss_pickup_window_deduction_bonus?.id
            ? [miss_pickup_window_deduction_bonus]
            : []),
        ...(!early_late_pickup_deduction_bonus?.id &&
        (early_late_pickup_deduction_bonus?.field_value ||
          early_late_pickup_deduction_bonus?.price_value) &&
        !!add_early_late_pickup_deduction_bonus
          ? [
              {
                id: 0,
                action_type: "deduction",
                bonus_type: "default",
                field: "early_late_pickup",
                field_value: early_late_pickup_deduction_bonus?.field_value || 0,
                price_type: early_late_pickup_deduction_bonus?.price_type || "amount",
                price_value: early_late_pickup_deduction_bonus?.price_value || 0
              }
            ]
          : early_late_pickup_deduction_bonus?.id
            ? [early_late_pickup_deduction_bonus]
            : [])
      ]
    };

    if (editedUserId) {
      setSaveLoading(true);
      if (avatar?.file) {
        axios
          .post(`/employee/${editedUserId}/avatar`, avatar.file)
          .then(() => {
            axios
              .put("/employee", body)
              .then((response) => {
                if (response.success) {
                  dispatch(
                    toastActions.toast({ code: "success", message: "Employee successfully edited" })
                  );
                  setIsEditComplete && setIsEditComplete(true);
                  handleCurrentClose();
                }
              })
              .catch((err) =>
                dispatch(
                  toastActions.toast({
                    code: "error",
                    message: err.response.data?.errors
                      ? Object.values(err.response.data?.errors).join("\n")
                      : err.response?.data?.message || err.message
                  })
                )
              );
          })
          .catch((err) => {
            if (err.response?.status === 422 || err.code === "ERR_NETWORK") {
              setAvatar();
              dispatch(
                toastActions.toast({
                  code: "error",
                  message: "File is too large, try again with a smaller file "
                })
              );
            } else {
              dispatch(
                toastActions.toast({
                  code: "error",
                  message: err.response?.data?.message || err.message
                })
              );
            }
          })
          .finally(() => setSaveLoading(false));
      } else {
        axios
          .put("/employee", body)
          .then((response) => {
            if (response.success) {
              dispatch(
                toastActions.toast({ code: "success", message: "Employee successfully edited" })
              );
              setIsEditComplete && setIsEditComplete(true);
              handleCurrentClose();
            }
          })
          .catch((err) =>
            dispatch(
              toastActions.toast({
                code: "error",
                message: err.response.data?.errors
                  ? Object.values(err.response.data?.errors).join("\n")
                  : err.response?.data?.message || err.message
              })
            )
          )
          .finally(() => setSaveLoading(false));
      }
    } else {
      setSaveLoading(true);

      axios
        .post("/employee", body)
        .then((response) => {
          if (response.success) {
            if (avatar?.file) {
              axios
                .post(`/employee/${response.data?.employee?.id}/avatar`, avatar.file)
                .then(() => {
                  dispatch(
                    toastActions.toast({ code: "success", message: "Employee successfully added" })
                  );
                  handleCurrentClose();
                  setIsEditComplete && setIsEditComplete(true);
                })
                .catch((err) =>
                  dispatch(
                    toastActions.toast({
                      code: "error",
                      message: err.response?.data?.message || err.message
                    })
                  )
                );
            } else {
              dispatch(
                toastActions.toast({ code: "success", message: "Employee successfully added" })
              );
              handleCurrentClose();
              setIsEditComplete && setIsEditComplete(true);
            }
          }
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response.data?.errors
                ? Object.values(err.response.data?.errors).join("\n")
                : err.response?.data?.message || err.message
            })
          )
        )
        .finally(() => setSaveLoading(false));
    }
  };

  const curStations = useMemo(
    () => stations.filter((el) => el.company_id === primary_company),
    [primary_company]
  );

  const curCompanies = useMemo(() => (open ? companies : []), [open, companies]);
  const curPositions = useMemo(() => (open ? positions : []), [open, positions]);

  const handleAvatarChange = (e) => {
    if (e?.target?.files?.[0]) {
      const formData = new FormData();
      formData.append("file", e.currentTarget.files[0]);

      setAvatar({ file: formData, url: URL.createObjectURL(e.target.files[0]) });
    }
  };

  const handleNumberChange = (inputName, e) => {
    const value = e?.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
      return "." + m1.replace(/\./g, "");
    });
    setValue(inputName, value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handlePayHistoryOpen = () => setIsPayHistory(true);
  const handlePayHistoryClose = () => setIsPayHistory(false);

  const handleCompanyStationChange = (e) => {
    const targetValue = e?.target?.value;

    setValue("primary_station", targetValue, {
      shouldValidate: true,
      shouldDirty: true
    });
    setValue("contract_service_area", targetValue, {
      shouldValidate: true,
      shouldDirty: true
    });
    setValue("station_id", curStations.find((el) => el.csa === targetValue).id, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleCompanyChange = (e) => {
    const targetValue = e?.target?.value;

    setValue("primary_company", targetValue, {
      shouldValidate: true,
      shouldDirty: true
    });

    setValue("primary_station", null, {
      shouldDirty: true
    });

    setValue("station_id", null, {
      shouldDirty: true
    });
  };

  document?.body.addEventListener("keydown", function (e) {
    if (e.key == "Escape" && open) {
      handleCurrentClose();
    }
  });

  const handleActiveChange = () => {
    setActiveLoading(true);

    axios
      .put(`/employee/${editedUserId}/toggle-is-active`)
      .then((response) => {
        if (response.success) {
          dispatch(
            toastActions.toast({ code: "success", message: "Employee successfully edited" })
          );

          setIsEditComplete && setIsEditComplete(true);

          setIsActive(!isActive);

          if (editedUser.paychex_company_integrations.length) {
            dispatch(
              toastActions.toast({
                code: "success",
                message: "Please, manually change the Status on Paychex Flex"
              })
            );
          }
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({
            code: "error",
            message: err.response?.data?.message || err.message
          })
        )
      )
      .finally(() => setActiveLoading(false));
  };

  return (
    <div
      className="employees-popup add-employees-popup"
      style={{ display: open ? "flex" : "none" }}>
      <div className="popup-overlay-close" onClick={handleCurrentClose} />

      <div className="employees-inner">
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="popup-top-bar">
              <h2>
                {editedUser && !isPayHistory
                  ? "Edit Employee"
                  : isPayHistory
                    ? "Pay History"
                    : "Add New Employee"}
              </h2>
              <button className="popup-close add-popup-cancel" onClick={handleCurrentClose}>
                <img src={CloseCircle} alt="" />
              </button>
            </div>

            <div className="popup-dis-sec">
              {isPayHistory ? (
                <EmployeesModalPayHistory
                  editedUserId={editedUserId}
                  handlePayHistoryClose={handlePayHistoryClose}
                />
              ) : (
                <>
                  <div className="popup-content-sec-main basic-info-sec">
                    <div className="profile-sec employees-popup-profile-sec">
                      {isGusto && (
                        <Tooltip
                          arrow
                          title={
                            <div className="employees-popup-profile-tooltip">
                                {isGustoOnboarded ? (
                                    <span>Employee is active</span>
                                ) : (
                                    <span>Employee is in onboarding status. Please complete the process on Gusto.</span>
                                )}
                                {latesIntegration && (
                                    <div>
                                        Last sync: {dateToHumanDiff(latesIntegration.updated_at)}
                                    </div>
                                )}
                            </div>
                          }>
                          <div className="employees-popup-profile-gusto">
                            <GustoIcon />
                          </div>
                        </Tooltip>
                      )}
                        {isPaychex && (
                            <>
                                <Tooltip
                                    arrow
                                    title={
                                        <div className="employees-popup-profile-tooltip">
                                            Connected to Paychex
                                        </div>
                                    }>
                                    <div className="employees-popup-profile-paychex">
                                        <img src={PaychexLogo} />
                                    </div>
                                </Tooltip>
                            </>
                        )}
                      <div className="profile-img profile-info">
                        <Avatar
                          variant="circular"
                          className="employees-profile-img"
                          alt={editedUser?.first_name}
                          src={avatar?.url || editedUser?.profile_image}
                        />
                        {!saveLoading && (
                          <button className="employees-profile-img-upload profile-info">
                            <img src={EditBig} alt="" />
                            <input
                              disabled={!isActive}
                              type="file"
                              id="file-uploader"
                              accept="image/png, image/jpeg"
                              onChange={handleAvatarChange}
                            />
                          </button>
                        )}
                      </div>

                      <div className="employee-modal-header-buttons">
                        {editedUserId && (
                          <>
                            <FormControlLabel
                              control={<Switch checked={isActive} />}
                              label={isActive ? "Active" : "Inactive"}
                              sx={styles.switch}
                              disabled={activeLoading}
                              onChange={handleActiveChange}
                            />
                            <button
                              onClick={handlePayHistoryOpen}
                              className="btn-style upload-fuel-btn pay-history">
                              Pay History
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <form className="new-employees-basic-info">
                      <div className="form-item">
                        <label className={errors.first_name?.message && "error"}>
                          First Name *
                        </label>
                        <div className="input-box">
                          <TextField
                            sx={errors.first_name?.message ? styles.error : {}}
                            fullWidth
                            type="text"
                            placeholder="First Name"
                            name="first_name"
                            required
                            disabled={!isActive}
                            {...register("first_name")}
                            error={touchedFields.first_name && !!errors.first_name?.message}
                            helperText={errors.first_name?.message}
                          />
                        </div>
                      </div>
                      <div className="form-item">
                        <label className={errors.last_name?.message && "error"}>Last Name *</label>
                        <div className="input-box">
                          <TextField
                            sx={errors.last_name?.message ? styles.error : {}}
                            fullWidth
                            type="text"
                            placeholder="Last Name"
                            name="last_name"
                            required
                            disabled={!isActive}
                            className={isDemoMode && editedUserId ? "blur-text" : ""}
                            {...register("last_name")}
                            error={touchedFields.last_name && !!errors.last_name?.message}
                            helperText={errors.last_name?.message}
                          />
                        </div>
                      </div>
                      <div className="form-item">
                        <label className={errors.email?.message && "error"}> Email </label>
                        <div className="input-box">
                          <TextField
                            sx={errors.email?.message ? styles.error : {}}
                            fullWidth
                            type="email"
                            placeholder="Email"
                            name="email"
                            disabled={!isActive}
                            className={isDemoMode && editedUserId ? "blur-text" : ""}
                            {...register("email")}
                            error={touchedFields.email && !!errors.email?.message}
                            helperText={errors.email?.message}
                          />
                        </div>
                      </div>
                      <div className="form-item">
                        <label>Phone Number</label>
                        <div
                          className={
                            isDemoMode && editedUserId ? "blur-text input-box" : "input-box"
                          }>
                          <InputMask
                            mask="(999) 999-9999"
                            value={phone}
                            disabled={false}
                            onChange={(data) => setPhone(data?.target?.value)}
                            maskChar=" ">
                            {() => <TextField className="input-mask" />}
                          </InputMask>
                        </div>
                      </div>
                      <div className="form-item">
                        <label className={errors.fedex_id?.message && "error"}>FedEx ID *</label>
                        <div className="input-box">
                          <TextField
                            sx={errors.fedex_id?.message ? styles.error : {}}
                            fullWidth
                            type="text"
                            placeholder="FedEx ID"
                            required
                            name="fedex_id"
                            disabled={!isActive}
                            className={isDemoMode && editedUserId ? "blur-text" : ""}
                            {...register("fedex_id")}
                            onChange={handleNumberChange.bind(null, "fedex_id")}
                            error={touchedFields.fedex_id && !!errors.fedex_id?.message}
                            helperText={errors.fedex_id?.message}
                          />
                        </div>
                      </div>
                      <div className="form-item">
                        <label className={errors.employee_id?.message && "error"}>
                          Employee ID
                        </label>
                        <div className="input-box">
                          <TextField
                            sx={errors.employee_id?.message ? styles.error : {}}
                            fullWidth
                            type="text"
                            placeholder="Employee ID"
                            // required
                            name="employee_id"
                            disabled={!isActive || isPaychex}
                            className={isDemoMode && editedUserId ? "blur-text" : ""}
                            {...register("employee_id")}
                            // onChange={handleNumberChange.bind(null, "employee_id")}
                            error={touchedFields.employee_id && !!errors.employee_id?.message}
                            helperText={errors.employee_id?.message}
                          />
                        </div>
                      </div>
                      <div className="form-item">
                        <label className={errors.primary_company?.message && "error"}>
                          Primary Company *
                        </label>
                        <div className="input-box">
                          <Select
                            sx={errors.primary_company?.message ? styles.errorSelect : {}}
                            required
                            className="employees-select"
                            name="primary_company"
                            disabled={!isActive}
                            value={primary_company || ""}
                            {...register("primary_company")}
                            onChange={handleCompanyChange}
                            IconComponent={KeyboardArrowDown}>
                            {curCompanies?.map((el) => (
                              <MenuItem key={el.id} value={el.id}>
                                {el.name}
                              </MenuItem>
                            ))}
                          </Select>

                          {!!errors.primary_company?.message && (
                            <FormHelperText
                              component="div"
                              error={!!errors.primary_company?.message}
                              sx={styles.selectErrorMessage}>
                              {errors.primary_company?.message}
                            </FormHelperText>
                          )}
                        </div>
                      </div>
                      <div className="form-item">
                        <label className={errors.primary_station?.message && "error"}>
                          Primary Station *
                        </label>
                        <div className="input-box">
                          <Select
                            sx={errors.primary_station?.message ? styles.errorSelect : {}}
                            required
                            disabled={!primary_company || !isActive}
                            className="employees-select"
                            name="primary_station"
                            value={primary_station || ""}
                            {...register("primary_station")}
                            onChange={handleCompanyStationChange}
                            IconComponent={KeyboardArrowDown}>
                            {curStations?.map((el) => (
                              <MenuItem key={el.id} value={el.csa}>
                                {el.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {!!errors.primary_station?.message && (
                            <FormHelperText
                              component="div"
                              error={!!errors.primary_station?.message}
                              sx={styles.selectErrorMessage}>
                              {errors.primary_station?.message}
                            </FormHelperText>
                          )}
                        </div>
                      </div>
                      <div className="form-item">
                        <label className={errors.contract_service_area?.message && "error"}>
                          Contract Service Area *
                        </label>
                        <div className="input-box">
                          <TextField
                            sx={errors.contract_service_area?.message ? styles.error : {}}
                            required
                            fullWidth
                            type="text"
                            placeholder="Contract Service Area"
                            disabled
                            name="contract_service_area"
                            className={isDemoMode && editedUserId ? "blur-text" : ""}
                            {...register("contract_service_area")}
                            onChange={handleNumberChange.bind(null, "contract_service_area")}
                            error={
                              touchedFields.contract_service_area &&
                              !!errors.contract_service_area?.message
                            }
                            helperText={errors.contract_service_area?.message}
                          />
                        </div>
                      </div>
                      <div className="form-item">
                        <label> Company Position </label>
                        <div className="input-box">
                          <Select
                            className="employees-select"
                            name="company_position_id"
                            value={company_position_id}
                            disabled={!isActive}
                            {...register("company_position_id")}
                            IconComponent={KeyboardArrowDown}
                            error={
                              touchedFields.company_position_id &&
                              !!errors.company_position_id?.message
                            }>
                            {curPositions?.map((el) => (
                              <MenuItem key={el.id} value={el.id}>
                                {el.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="popup-content-sec-main new-employees-pay-adjustment">
                        <h5>Weekly Salary</h5>
                        <EmployeesPayAdjustment
                          is_overtime_enabled={is_overtime_enabled}
                          add_stop_bonus={add_stop_bonus}
                          add_package_bonus={add_package_bonus}
                          add_per_package={add_per_package}
                          add_ils_addition_bonus={add_ils_addition_bonus}
                          add_ils_deduction_bonus={add_ils_deduction_bonus}
                          add_all_status_code_package_deduction_bonus={
                            add_all_status_code_package_deduction_bonus
                          }
                          add_dna_deduction_bonus={add_dna_deduction_bonus}
                          add_per_stop={add_per_stop}
                          add_miss_pickup_window_deduction_bonus={
                            add_miss_pickup_window_deduction_bonus
                          }
                          add_early_late_pickup_deduction_bonus={
                            add_early_late_pickup_deduction_bonus
                          }
                          add_code_85_deduction_bonus={add_code_85_deduction_bonus}
                          all_status_code_package_deduction_bonus={
                            all_status_code_package_deduction_bonus
                          }
                          dna_deduction_bonus={dna_deduction_bonus}
                          early_late_pickup_deduction_bonus={early_late_pickup_deduction_bonus}
                          miss_pickup_window_deduction_bonus={miss_pickup_window_deduction_bonus}
                          ils_addition_bonus={ils_addition_bonus}
                          ils_deduction_bonus={ils_deduction_bonus}
                          register={register}
                          touchedFields={touchedFields}
                          errors={errors}
                          isActive={isActive}
                          adding_daily_pay={adding_daily_pay}
                          setValue={setValue}
                          code_85_deduction_bonus={code_85_deduction_bonus || []}
                          stop_bonuses={stop_bonuses || []}
                          package_bonuses={package_bonuses || []}
                          deleted_stop_bonuses={deleted_stop_bonuses || []}
                          deleted_package_bonuses={deleted_package_bonuses || []}
                          deleted_code_85_deduction_bonus={deleted_code_85_deduction_bonus || []}
                          weekly_rate_w2={weekly_rate_w2}
                          is_work_area_enabled={is_work_area_enabled}
                          customErrors={customErrors}
                          setCustomErrors={setCustomErrors}
                        />
                      </div>

                      <div className="notes-into">
                        <label>Notes</label>
                        <TextField
                          sx={errors.notes?.message ? styles.error : {}}
                          fullWidth
                          multiline
                          rows={4}
                          type="text"
                          placeholder="Notes.."
                          name="notes"
                          required
                          disabled={!isActive}
                          {...register("notes")}
                          error={touchedFields.notes && !!errors.notes?.message}
                          helperText={errors.notes?.message}
                        />
                      </div>
                    </form>
                  </div>

                  <div className="popup-btns-sec">
                    <button
                      className="btn-style blank-btn add-popup-cancel"
                      onClick={handleCurrentClose}>
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit(onSubmit)}
                      className="btn-style add-popup-save"
                      type="submit"
                      disabled={saveLoading || !isActive || Object.keys(customErrors).length}>
                      {editedUser ? "Save" : "Add"}
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeesModal;
