import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { useSelector } from "react-redux";
import { getUser } from "../store/auth/auth.selectors";

dayjs.extend(weekday);

export const getPayAdjustment = (employee) => {
    return employee?.rates?.weekly_rate_w2 ? "Weekly" : "Daily";
};

export const getThisWeek = () => {
    const curDayToInWeek = dayjs().day();

    if (curDayToInWeek < 3) {
        return dayjs().weekday(6).subtract(3, "week").format("YYYY-MM-DD");
    } else {
        return dayjs().weekday(6).subtract(2, "week").format("YYYY-MM-DD");
    }
};

export const initiateFileDownloadFromBlob = (blob, fileName) => {
    // Create a URL for the Blob object.
    const fileURL = window.URL.createObjectURL(blob);

    // Get the extension file
    const extension = blob.type.split('/')[1];

    // Create a temporary download link element.
    const downloadLink = document?.createElement('a');
    downloadLink.href = fileURL;
    downloadLink.setAttribute('download', `${fileName}.${extension}`); // Set the download file name.

    // Append the link to the document and trigger the download.
    document?.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link and revoking the created URL.
    document?.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(fileURL);
};

export const isUserSourceRestricted = () => {
    const userData = JSON.parse(localStorage.getItem('user'));

    const restrictedSources = ['package_route'];

    if (userData && userData.organization) {
        return restrictedSources.includes(userData.organization.source);
    }

    return false;
};

export const defaultSettingPage = () => {
    // companies for user source restricted
    if(isUserSourceRestricted()) {
        return "companies";
    }

    // default page for other users
    return "profile";
};

export const redirectUserSourceRestricted = (currentPage, setPage, nav) => {
    const restrictedPages = ['profile', 'subscription', 'users'];
    if(isUserSourceRestricted() && restrictedPages.includes(currentPage)) {
        setPage('companies');

        nav({
            pathname: "/settings",
            search: `?page=companies`
        });
    }
};

export const dateToHumanDiff = (date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(date)) / 1000);

    const intervals = [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'week', seconds: 604800 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 }
    ];

    for (const interval of intervals) {
        const intervalCount = Math.floor(diffInSeconds / interval.seconds);
        if (intervalCount >= 1) {
            return new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(-intervalCount, interval.label);
        }
    }

    return 'Just now';
}
