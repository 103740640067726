import ManualApplicationsRow from "./ManualApplicationsRow";

const ManualApplicationsList = ({ companiesList, handleEdit, handleChangeCompany, directIntegrations, templateIntegrations }) => {
  return (
    <div className="companies-list">
      <ul>
        {companiesList.map((el) => (
          <ManualApplicationsRow
            key={el.id}
            itemData={el}
            handleEdit={handleEdit}
            handleChangeCompany={handleChangeCompany}
            directIntegrations={directIntegrations}
            templateIntegrations={templateIntegrations}
          />
        ))}
      </ul>
    </div>
  );
};

export default ManualApplicationsList;
