import { useNavigate } from "react-router-dom";

const TimeCardPreviewTableRow = ({ itemData }) => {
  const nav = useNavigate();

  const { total_hours, station_name, company_name, id } = itemData;

  const handleStatementClick = () => nav(`${id}`);

  return (
    <tr onClick={handleStatementClick}>
      <td style={{ maxWidth: 85 }}>{company_name}</td>
      <td>{station_name}</td>
      <td>total_hours</td>
    </tr>
  );
};

export default TimeCardPreviewTableRow;
