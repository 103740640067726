import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../common/Loading";

import TableSortArrowsBlock from "../common/TableSortArrowsBlock";
import { ReactComponent as Printer } from "../../assets/images/run-payroll-preview/printer.svg";
import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import axios from "../../axios";
import { initiateFileDownloadFromBlob } from "../../helpers/helpers";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import { actions as breadCrumbsActions } from "../../store/bread-crumbs/bread-crumbs.reducers";
import PDF from "../../assets/images/run-payroll-preview/pdf.svg";
import CSV from "../../assets/images/run-payroll-preview/csv.svg";
import { getBreadCrumbs } from "../../store/bread-crumbs/bread-crumbs.selectors";
import dayjs from "dayjs";
import TimeCardPreviewOneTableRow from "./TimeCardPreviewOneTableRow";

const TimeCardPreviewOneRow = () => {
  const dispatch = useDispatch();
  const { payrollId, statementId } = useParams();

  const [isPrintPDFInProgress, setIsPrintPDFInProgress] = useState(false);
  const [isPrintCSVInProgress, setIsPrintCSVInProgress] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortItem, setSortItem] = useState();
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const breadCrumbs = useSelector(getBreadCrumbs);

  const isMobile = useMediaQuery("(max-width:990px)");

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    });

  const currentEmployees = useMemo(() => {
    if (!sortItem) {
      return employees;
    }

    const isLastNameAsc = sortItem.name === "employee_name" && sortItem.direction === "asc";
    const isFirstNameDesc = sortItem.name === "employee_name" && sortItem.direction === "desc";

    return [...employees].sort((a, b) => {
      if (isLastNameAsc) {
        return a.employee.last_name.localeCompare(b.employee.last_name);
      } else if (isFirstNameDesc) {
        return a.employee.first_name.localeCompare(b.employee.first_name);
      } else if (sortItem.direction === "asc") {
        return a[sortItem.name] < b[sortItem.name] ? -1 : 1;
      } else {
        return a[sortItem.name] > b[sortItem.name] ? -1 : 1;
      }
    });
  }, [employees, sortItem]);

  const fetchStatementData = () => {
    axios
      .get(`statement/${statementId}`)
      .then((response) => {
        const statementData = response.data;
        if (breadCrumbs.length === 0 || breadCrumbs.length > 1) {
          dispatch(
            breadCrumbsActions.setBreadCrumbs([
              {
                name: "Payroll",
                url: `/reports/time-card`
              },
              {
                name: `${dayjs(statementData.date).format("MM/DD/YYYY")} - ${dayjs(
                  statementData.date
                )
                  .add(6, "d")
                  .format("MM/DD/YYYY")}`,
                url: `/time-card-preview/${payrollId}`
              },
              {
                name: `${statementData.company_name}, ${statementData.station_name} ${statementData.station_id}`
              }
            ])
          );
        } else {
          dispatch(
            breadCrumbsActions.setBreadCrumbs([
              ...breadCrumbs,
              {
                name: `${statementData.company_name}, ${statementData.station_name} ${statementData.station_id}`
              }
            ])
          );
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  const fetchTimeCardEmployees = () => {
    setLoading(true);
    axios
      .get(`/payroll/${payrollId}/${statementId}/time-card`)
      .then((response) => {
        setEmployees(response.data);
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchTimeCardEmployees();
    fetchStatementData();
  }, []);

  const handlePrintPDFReport = (e) => {
    setIsPrintPDFInProgress(true);

    e.preventDefault();
    axios({
      method: "get",
      url: `/payroll/${statementId}/download/time-card/pdf-report`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "statement-report");
      })
      .catch(() => dispatch(toastActions.toast({ code: "error", message: "Something went wrong" })))
      .finally(() => {
        setIsPrintPDFInProgress(false);
        handleMenuClose();
      });
  };

  const handlePrintCSVReport = (e) => {
    setIsPrintCSVInProgress(true);
    handleMenuClose();

    e.preventDefault();
    axios({
      method: "get",
      url: `/payroll/${statementId}/download/time-card/csv-report`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "statement-report");
      })
      .catch(() => dispatch(toastActions.toast({ code: "error", message: "Something went wrong" })))
      .finally(() => {
        setIsPrintCSVInProgress(false);
        handleMenuClose();
      });
  };

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <Loading />
        </div>
      ) : (
        <div className="run-payroll-data statement">
          <div className="payroll-header">
            <button
              onClick={handleMenuOpen}
              className="btn-style upload-fuel-btn upload-fuel-no-margin dsw">
              <Printer />
              {!isMobile && "Print"}
            </button>
          </div>

          <div className="run-payroll-data-statement-content">
            <div className="payroll-table payroll-data-table statement">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Employee Name{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="last_name"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Total Week{" "}
                        <TableSortArrowsBlock
                          handleSortChange={handleSortChange}
                          itemName="total_week"
                          activeSortedItem={sortItem}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {currentEmployees.map((el) => (
                    <TimeCardPreviewOneTableRow key={el.id} itemData={el} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            className="more-menu report statement">
            <MenuItem onClick={handlePrintPDFReport}>
              <button className="document-more-btn menu-item" disabled={isPrintPDFInProgress}>
                <img src={PDF} alt="" />
              </button>
              PDF
            </MenuItem>
            <MenuItem onClick={handlePrintCSVReport}>
              <button className="document-more-btn menu-item" disabled={isPrintCSVInProgress}>
                <img src={CSV} alt="" />
              </button>
              CSV
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  );
};

export default TimeCardPreviewOneRow;
