import "../assets/css/run-payroll.css";

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import axios from "../axios";
import { actions as toastActions } from "../store/toast/toast.reducers";
import { actions } from "../store/time-card/time-card.reducers";
import { getBreadCrumbs } from "../store/bread-crumbs/bread-crumbs.selectors";
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers";
import Loading from "../components/common/Loading";
import TableSortArrowsBlock from "../components/common/TableSortArrowsBlock";
import TimeCardPreviewTableRow from "../components/time-card/TimeCardPreviewTableRow";
import { ReactComponent as Printer } from "../assets/images/run-payroll-preview/printer.svg";
import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import PDF from "../assets/images/run-payroll-preview/pdf.svg";
import CSV from "../assets/images/run-payroll-preview/csv.svg";
import { initiateFileDownloadFromBlob } from "../helpers/helpers";
import { getTimeCardAllData, getTimeCardArr } from "../store/time-card/time-card.selectors";

const TimeCardPreview = () => {
  const dispatch = useDispatch();
  const { payrollId } = useParams();

  const breadCrumbs = useSelector(getBreadCrumbs);
  const timeCardAllData = useSelector(getTimeCardAllData);
  const timeCardArr = useSelector(getTimeCardArr);

  const [loading, setLoading] = useState(false);
  const [sortItem, setSortItem] = useState();

  const [isPrintPDFInProgress, setIsPrintPDFInProgress] = useState(false);
  const [isPrintCSVInProgress, setIsPrintCSVInProgress] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isMobile = useMediaQuery("(max-width:990px)");

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  useEffect(() => {
    if (timeCardAllData.date && (breadCrumbs.length === 0 || breadCrumbs.length > 1)) {
      dispatch(
        breadCrumbsActions.setBreadCrumbs([
          {
            name: "Time Card",
            url: `/reports/time-card`
          },
          {
            name: `${dayjs(timeCardAllData.date).format("MM/DD/YYYY")} - ${dayjs(
              timeCardAllData.date
            )
              .add(6, "d")
              .format("MM/DD/YYYY")}`
          }
        ])
      );
    } else if (timeCardAllData.date && breadCrumbs.length === 1) {
      dispatch(
        breadCrumbsActions.setBreadCrumbs([
          ...breadCrumbs,
          {
            name: `${dayjs(timeCardAllData.date).format("MM/DD/YYYY")} - ${dayjs(
              timeCardAllData.date
            )
              .add(6, "d")
              .format("MM/DD/YYYY")}`
          }
        ])
      );
    }
  }, [timeCardAllData]);

  useEffect(() => {
    fetchPayrollData();

    return () => {
      dispatch(actions.resetPayroll());
    };
  }, []);

  const fetchPayrollData = () => {
    setLoading(true);

    axios
      .get(`/payroll/${payrollId}/time-card`)
      .then((response) => {
        const payrollData = response.data.payroll;

        dispatch(actions.setTotalCardAllData(payrollData));
        dispatch(actions.setTotalCardArr(payrollData.total_card));
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    });

  const currentStatements = useMemo(() => {
    if (!sortItem) {
      return timeCardArr;
    }
    if (sortItem.direction === "asc") {
      return [...timeCardArr].sort((a, b) => (a[sortItem.name] < b[sortItem.name] ? -1 : 1));
    }
    return [...timeCardArr].sort((a, b) => (a[sortItem.name] > b[sortItem.name] ? -1 : 1));
  }, [timeCardArr, sortItem]);

  const handlePrintPDFReport = (e) => {
    setIsPrintPDFInProgress(true);

    e.preventDefault();
    axios({
      method: "get",
      url: `/payroll/${payrollId}/download/time-card/pdf-report`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "report-time-card");
      })
      .catch(() => dispatch(toastActions.toast({ code: "error", message: "Something went wrong" })))
      .finally(() => {
        setIsPrintPDFInProgress(false);
        handleMenuClose();
      });
  };

  const handlePrintCSVReport = (e) => {
    setIsPrintCSVInProgress(true);
    handleMenuClose();

    e.preventDefault();
    axios({
      method: "get",
      url: `/payroll/${payrollId}/download/time-card/csv-report`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "report-time-card");
      })
      .catch(() => dispatch(toastActions.toast({ code: "error", message: "Something went wrong" })))
      .finally(() => {
        setIsPrintCSVInProgress(false);
        handleMenuClose();
      });
  };

  return (
    <div className="run-payroll-data">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="payroll-header">
            <button
              onClick={handleMenuOpen}
              className="btn-style upload-fuel-btn upload-fuel-no-margin dsw">
              <Printer />
              {!isMobile && "Print"}
            </button>
          </div>

          <div className="payroll-table preview payroll-data-table expenses-preview">
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Company{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="company_name"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Station{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="station_name"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="leaderboard-top-table-header-item">
                      Total hours{" "}
                      <TableSortArrowsBlock
                        handleSortChange={handleSortChange}
                        itemName="total_hours"
                        activeSortedItem={sortItem}
                      />
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentStatements.map((el) => (
                  <TimeCardPreviewTableRow key={el.id} itemData={el} />
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose} className="more-menu report">
        <MenuItem onClick={handlePrintPDFReport}>
          <button className="document-more-btn menu-item" disabled={isPrintPDFInProgress}>
            <img src={PDF} alt="" />
          </button>
          PDF
        </MenuItem>
        <MenuItem onClick={handlePrintCSVReport}>
          <button className="document-more-btn menu-item" disabled={isPrintCSVInProgress}>
            <img src={CSV} alt="" />
          </button>
          CSV
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TimeCardPreview;
