import dayjs from "dayjs";
import Avatar from "../common/Avatar";
import EmptyState from "./../common/EmptyState";
import BlurText from "../common/BlurText";

const TimeCardPreviewOneTableRow = ({ itemData }) => {
  const { clock, company_position_name, first_name, last_name, profile_image, total_week } =
    itemData;

  return (
    <>
      <tr
        style={{
          background: "#FFFFFF",
          borderLeft: "1px solid #EEF0F2",
          borderRight: "1px solid #EEF0F2"
        }}>
        <td className={"no-hover-effect"}>
          <div className="statement-first-block">
            <Avatar
              variant="circular"
              className="statement-avatar"
              alt={first_name}
              src={profile_image}
            />
            <div className="statement-name-block">
              <div className="statement-name-block-content">
                <div>
                  {first_name} <BlurText>{last_name}</BlurText>{" "}
                </div>
              </div>

              <div className="statement-company">{company_position_name}</div>
            </div>
          </div>
        </td>
        <td className={"no-hover-effect"} style={{ fontWeight: 700 }}>
          {total_week}
        </td>
      </tr>

      <tr
        className="statement-more-data-container time-card"
        style={{
          borderLeft: "1px solid #EEF0F2",
          borderRight: "1px solid #EEF0F2"
        }}>
        <td colSpan="16">
          {clock?.length > 0 ? (
            <div className="statement-more-data-table time-card-table">
              <table>
                <thead className="statement-more-data-table-header">
                  <tr>
                    <th>Date</th>
                    <th>Clock In</th>
                    <th>Clock Out</th>
                    <th style={{ fontWeight: 700 }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {clock.map((el) => (
                    <tr key={el.id}>
                      <td>{dayjs(el.created_at).format("MM/DD/YYYY - dddd")}</td>
                      <td>{el.clock_in}</td>
                      <td>{el.clock_out}</td>
                      <td style={{ fontWeight: 700 }}>{el.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <EmptyState title="No Daily Summary" />
          )}
        </td>
      </tr>
    </>
  );
};

export default TimeCardPreviewOneTableRow;
