import { useNavigate } from "react-router-dom";
import * as dayjs from "dayjs";
import FolderIcon from "../../assets/images/run-payroll/folder.png";

const TimeCardTableRow = ({ itemData }) => {
  const nav = useNavigate();

  const { date, statements_count, created_at, total_hours, updated_at } = itemData;

  const handlePreviewClick = () => nav(`/time-card-preview/${itemData.id}`);

  return (
    <tr>
      <td onClick={handlePreviewClick} className="pointer">
        <img src={FolderIcon} alt="" />
        <strong>
          {dayjs(date).format("MM/DD/YYYY")} - {dayjs(date).add(6, "d").format("MM/DD/YYYY")}
        </strong>
        <span>{statements_count} Statements</span>
      </td>
      <td onClick={handlePreviewClick} className="pointer">
        {dayjs(created_at).format("MMM DD, YYYY hh:mm a")}
      </td>
      <td onClick={handlePreviewClick} className="pointer">
        {dayjs(updated_at).format("MMM DD, YYYY hh:mm a")}
      </td>
      <td onClick={handlePreviewClick} className="pointer text-align-right">
        {/* {total_hours} */}
        total_hours
      </td>
    </tr>
  );
};

export default TimeCardTableRow;
