import { ReactComponent as Edit } from "../../../assets/images/settings/edit.svg";

const ManualApplicationsCompanyRow = ({ companyData, handleEdit, type }) => {

  const isPaychex = type === "Paychex";
  const isADPv2 = type === "ADP Run GTSG"
  console.log(type);
  const isADP = type === "ADP Run"

  return (
    <div className="applications-company-row-item">
      <div className="applications-company-row-item-container">
        <div className="applications-company-row-item-name">{companyData.name}</div>
        {isPaychex ? (
          <>
            <div className="applications-company-row-item-text co-code">
              <span>Company ID:</span>
              {companyData.paychex_company_id}
            </div>
            <div className="applications-company-row-item-text co-code">
              <span>Level 2:</span>
              {companyData.paychex_level_2_code}
            </div>
            <div className="applications-company-row-item-text co-code">
              <span>Level 3:</span>
              {[true, "true"].includes(companyData.paychex_level_3_code) ? "On" : "Off"}
            </div>
          </>
        ) : null}
        {
          isADP ? (
              <div className="applications-company-row-item-text co-code">
                <span>Co Code:</span>
                {companyData.adp_co_code}
              </div>
          ): null
        }
        {
          isADPv2 ? (
            <div className="applications-company-row-item-text co-code">
              <span>IID:</span>
              {companyData.adp_iid}
            </div>
          ): null
        }
      </div>

      <button
        className="companies-btn applications edit"
        onClick={handleEdit.bind(null, companyData, type)}>
        <Edit />
      </button>
    </div>
  );
};

export default ManualApplicationsCompanyRow;
