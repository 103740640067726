import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  timeCardArr: [],
  timeCardAllData: {}
};

export const { actions, reducer } = createSlice({
  name: 'timeCard',
  initialState,
  reducers: {
    setTotalCardAllData: (state, action) => {
      state.timeCardAllData = action.payload;
    },
    setTotalCardArr: (state, action) => {
      state.timeCardArr = action.payload;
    },
    resetPayroll: (state) => {
      state.timeCardArr = [];
      state.timeCardAllData = {};
    }
  }
});

export default reducer;
