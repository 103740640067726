import { useNavigate } from "react-router-dom";
import "../assets/css/report-page.css";

import TitleInfo from "./../components/common/TitleInfo";

const Reports = () => {
  const nav = useNavigate();

  const handleExpensesClick = () => nav("expenses");
  const handleReportsClick = () => nav("time-card");

  return (
    <>
      <TitleInfo title="Reports" subTitle="Streamline your workflow, effortlessly" />

      <div className="report-page">
        <div className="report-page-item" onClick={handleExpensesClick}>
          <h5>Expenses</h5>
          <p>Click to go to the Expenses page</p>
        </div>

        <div className="report-page-item" onClick={handleReportsClick}>
          <h5>Time Card</h5>
          <p>Click to go to the Time Card page</p>
        </div>
      </div>
    </>
  );
};

export default Reports;
