import "../assets/css/run-payroll.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleInfo from "./../components/common/TitleInfo";
import { actions as toastActions } from "../store/toast/toast.reducers";
import axios from "../axios";
import EmptyState from "../components/run-payroll/EmptyState";
import { getPayrollList } from "../store/payroll/payroll.selectors";
import { actions } from "../store/payroll/payroll.reducers";
import Loading from "../components/common/Loading";
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers";
import { useTableSortItem } from "./../hooks/useTableSortItem";
import TimeCardContent from "./../components/time-card/TimeCardContent";

const TimeCard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [displayBy, setDisplayBy] = useState(4);
  const { sortItem, setSortItem } = useTableSortItem("time-card");

  const payrolls = useSelector(getPayrollList);

  useEffect(() => {
    dispatch(
      breadCrumbsActions.setBreadCrumbs([
        {
          name: "Time card",
          url: `/reports/time-card`
        }
      ])
    );
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => fetchPayrollList(), 500);

    return () => {
      clearTimeout(handler);
    };
  }, [displayBy, sortItem]);

  const fetchPayrollList = () => {
    setLoading(true);

    axios
      .get(
        `/payroll?filter=${displayBy}&field=${sortItem?.name || ""}&direction=${
          sortItem?.direction || ""
        }`
      )
      .then((response) => dispatch(actions.setPayrollList(response.data.payrolls)))
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  const handleRangeChange = (e) => {
    setDisplayBy(e.target.value);
    localStorage.setItem("timeCardFilterBy", e.target.value);
    setSortItem();
  };

  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    });

  useEffect(() => {
    const timeCardFilterBy = localStorage.getItem("timeCardFilterBy");

    if (timeCardFilterBy) {
      setDisplayBy(+timeCardFilterBy);
    }
  }, []);

  return (
    <>
      <TitleInfo title="Time Card" subTitle="Select a date to see time card for it" />

      {loading ? (
        <div className="run-payroll-height">
          <Loading />
        </div>
      ) : payrolls.length ? (
        <TimeCardContent
          payrolls={payrolls}
          handleRangeChange={handleRangeChange}
          displayBy={displayBy}
          handleSortChange={handleSortChange}
          activeSortedItem={sortItem}
        />
      ) : (
        <EmptyState />
      )}
    </>
  );
};

export default TimeCard;
